var privacyStatCookie = {
  agreeBtn: '.js-cookie-agree-btn',

  init: function () {
    if (document.querySelector(this.agreeBtn)) {
      if (!this.getCookie('CookieAcceptance')) {
        document.querySelector(this.agreeBtn).addEventListener('click', function (e) {
          this.clickHandler(e)
        }.bind(this))
      } else {
        this.hide(document.querySelector(this.agreeBtn))
      }
    }
  },
  clickHandler: function (ev) {
    ev.preventDefault()
    this.setCookie('CookieAcceptance', 1, 365)
    this.hide(document.querySelector(this.agreeBtn))
  },
  setCookie: function (name, value, days) {
    var expires = ''
    if (days) {
      var date = new Date()
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; secure; path=/'
  },
  getCookie: function (name) {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  },
  hide: function (el) {
    el.parentNode.style.display = 'none'
  }
}

privacyStatCookie.init();
